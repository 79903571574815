import React, { useContext } from "react";
import OnlyOnDeviceLayout from "@src/layouts/OnlyOnDeviceLayout";
import { TranslationContext } from "@src/components/translation/TranslationContext";

function MobileOnly() {
  const t = useContext(TranslationContext);

  return <OnlyOnDeviceLayout text={t.translate("directus.page_mobile_only.subtitle")} />;
}

export default MobileOnly;
